import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components'

const Container = styled.div`
    max-width: calc(1200px - 2rem);
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 58px;
    button {
      font-family: 'Catamaran',sans-serif;
      color: var(--text);
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      border: none;
      background-color: #fff;
      cursor: pointer;
    }
`

const Pager = ({ pageContext }) => {

  const { previousPagePath, nextPagePath } = pageContext;
  return (
    <Container>
    <nav style={{ display: 'flex', justifyContent: 'space-between' }}>

    <div style={{ justifySelf: 'flex-end' }}>
    {nextPagePath && (
      <Link to={nextPagePath}>
        <button>← Older Posts</button>
      </Link>
    )}
    </div>

    <div><p>Page {pageContext.humanPageNumber}</p></div>

    <div>
      {previousPagePath && (
        <Link to={previousPagePath}>
          <button>Newer Posts →</button>
        </Link>
      )}
    </div>

    </nav>
    </Container>
  );
};

export default Pager;
