import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-plugin-sanity-image'
import { Link } from 'gatsby'
import { blockContentToPlainText } from 'react-portable-text'
import { artistname, slugify } from '../../lib/utils'
import DefaultImage from '../../images/news_default.jpg'

const Container = styled.div`
  max-width: calc(1200px - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 58px;
  @media(max-width: 880px) {
    flex-direction: column-reverse;
  }
  &:last-of-type {
    margin-bottom: 43px;
  }
  h2 {
    font-size: 43px;
    line-height: 52px;
    a {
      text-decoration: none;
      font-family: Catamaran, sans-serif;
      color: var(--text);
      transition: 0.25s all ease-in-out;
      &:hover {
        color: var(--pink);
      }
    }
  }
  p a {
    font-weight: 400;
  }
  @media(max-width: 880px) {
    h2 {
      font-size: 34px;
      line-height: 38px;
      text-align: center;
    }
  }
  img {
    max-width: 352px;
    border-radius: 14px;
    margin-right: 32px;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
  span {
    margin-left: 0.35rem;
    margin-right: 0.35rem;
  }
  @media(max-width: 880px) {
    margin-left: 0;
    p {
      text-align: center;
    }
  }
`

const ImageContainer = styled.div`
  width: 352px;
  height: 226px;
  background-color: #eee;
  border-radius: 14px;
  @media(max-width: 880px) {
    margin-top: 28px;
    width: 100%;
    height: 116px;
    img {
      margin-right: 0;
      max-width: none;
      object-position: center 0%;
    }
  }
  img.placeholder {
    object-fit: cover;
    height: 100%;
    width: 100%;
    @media(max-width: 880px) {
      object-position: center center;
    }
  }
`

const NewsPreview = ({ article, all_artists }) => {

  const { related_artists } = article;
  const url = "/news/" + slugify(article.title);
  const body = article._rawBodyBlocks ? blockContentToPlainText(article._rawBodyBlocks) : false;

  const mentioned_artists = [];
  const displayed_artists = [];

  // array of all artist names
  all_artists.map(artist => {
    if ((body && body.includes(artistname(artist))) || article.title.includes(artistname(artist))) {
      mentioned_artists.push(artist)
    }
  });

  // related artist names
  if (related_artists && related_artists.length > 0) {
    related_artists.map(r => displayed_artists.push(artistname(r)))
  }

  // extra links
  all_artists.map(e => {
    if (!displayed_artists.includes('Daniel Cook') && article.title.includes('Dan Cook') && artistname(e) === 'Daniel Cook') {
      mentioned_artists.push(e)
    }
  })

  // which artist to display a thumbnail of
  let first_artist = false;

  if (first_artist === false) {
    first_artist = related_artists && related_artists.length > 0 ? related_artists[0] : false;
  }

  if (first_artist === false) {
    first_artist = mentioned_artists && mentioned_artists.length > 0 ? mentioned_artists[0] : false;
  }

  return (
    <Container>
      <Link to={url}>
        <ImageContainer>

          {article?.image && article?.image?.asset !== null &&
            <Image
              {...article.image}
              width={352}
              height={226}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          }

          {article.image === null && first_artist === false &&
            <><img className="placeholder" src={DefaultImage} alt="" /></>
          }

          {article.image === null && first_artist !== false &&
            <Image
              {...first_artist.headshot}
              width={352}
              height={226}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          }

        </ImageContainer>
      </Link>
      <Inner>
        <h2><a href={url}>{article.title.replaceAll('*', '★')}</a></h2>
        <p>{article.date}

          {related_artists && related_artists.length > 0 &&
            <>
              {
                related_artists.map((r, index) => {
                  const artist_name = artistname(r);
                  const artist_link = '/artists/' + slugify(artist_name);
                  return (
                    <React.Fragment key={index}><span>|</span><Link key={index} to={artist_link}>{artist_name}</Link></React.Fragment>
                  )
                })
              }
            </>
          }

          {mentioned_artists && mentioned_artists.length > 0 &&
            <>
              {
                mentioned_artists.map((m, index) => {
                  const artist_name = artistname(m);
                  const artist_link = '/artists/' + slugify(artist_name);
                  if (!displayed_artists.includes(artist_name)) {
                    return (
                      <React.Fragment key={index}><span>|</span><Link key={index} to={artist_link}>{artist_name}</Link></React.Fragment>
                    )
                  }
                  displayed_artists.push(artist_name);
                })
              }
            </>
          }


        </p>


      </Inner>
    </Container>
  )
}

export default NewsPreview;
