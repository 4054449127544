import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Pager from '../pager';
import Title from '../title'
import NewsPreview from '../news_preview'
import Helmet from 'react-helmet'

export const newsArchiveQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allSanityArtist {
      edges {
        node {
          surname
          first_name
          headshot {
            ...ImageWithPreview
          }
        }
      }
    }
    allSanityNews(
        sort: { fields: date, order: DESC},
        skip: $skip,
        limit: $limit
        ) {
      edges {
        node {
          title
          date(formatString: "dddd D MMM, YYYY")
          _rawBodyBlocks(resolveReferences: {maxDepth: 10})
          body_blocks {
            _rawChildren
            _type
            list
            style
            children {
              _key
              _type
              text
              marks
            }
          }
          image {
            ...ImageWithPreview
            asset {
              publicUrl
            }
          }
        }
      }
    }
  }
`;

const NewsArchive = ({ data, pageContext, location }) => {

  const all_artists = data.allSanityArtist.edges.map(n => n.node)

  const posts = data.allSanityNews.edges;

  return (
    <div location={location}>

      <Helmet
        bodyAttributes={{
          class: 'news-page'
        }}
      />

      <Title noborder>News</Title>
      {posts.map(({ node }, index) => {
        return (
          <NewsPreview all_artists={all_artists} key={index} article={node} />
        )
      })}
      <Pager pageContext={pageContext} />
    </div>
  )
};

export default NewsArchive;
